/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedEventCategoryId } from "../../../store/slices/eventCategory";
import { setToDefault } from "store/slices/steps";
import { clearEventState } from "store/slices/event";

import Loader from "components/Loader";
import Tips, { TIPS_TYPE } from "containers/Tips";

import dogTip1 from "assets/events/dog-1.png";

import classNames from "classnames/bind";
import styles from "./unpublishedEvents.module.scss";
import { getUnPublishedEvent, selectEventsContent, selectEventList } from "store/slices/event";
import { lang } from "store/slices/langSlice";
import PaginationComponent from "components/PaginationComponent";
import EventCard from "components/Cards/EventCard/EventCard";
import { selectIsEventLoading } from "store/slices/event";
import { useTranslation } from "react-i18next";

const cx = classNames.bind(styles);

const UnpublishedEvent = () => {
  const { t } = useTranslation(["event"]);
  const dispatch = useDispatch();
  const [tipType, setTipType] = useState();
  const [activeStep, setActiveStep] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const isEventListLoading = useSelector(selectIsEventLoading);
  const selectedCategory = useSelector(selectSelectedEventCategoryId);
  const unpublishedEventListContent = useSelector(selectEventsContent);
  const unpublishedEventList = useSelector(selectEventList);
  const language = useSelector(lang);

  const cancelHandler = () => {
    setActiveStep(null);
    dispatch(setToDefault());
  };

  const isNeedToClearSelectedStep = () => {
    if (!selectedCategory && activeStep) {
      setActiveStep(null);
    }
  };

  const setTip = () => {
    setTipType(TIPS_TYPE.CHOOSE_CATEGORY);
  };

  const getEventsMethod = async (selectedPage) => {
    dispatch(getUnPublishedEvent({ category: selectedCategory, page: selectedPage }))
      .unwrap()
      .then()
      .catch();
  };

  useEffect(() => {
    if (selectedCategory && activeStep) {
      return;
    }
    isNeedToClearSelectedStep();
    setTip();
  }, [selectedCategory]);

  useEffect(() => {
    selectedCategory && getEventsMethod(0);
  }, [selectedCategory]);

  useEffect(() => {
    document.title = t("unpublishedEvent");

    dispatch(clearEventState());
  }, []);

  const handleSuccess = () => {
    setSelectedPage(0);
    getEventsMethod(0);
  };

  const renderEventCard = (el) => (
    <EventCard
      type="unPublished"
      content={unpublishedEventListContent}
      lang={language}
      getEvents={getEventsMethod}
      item={el}
      key={el.uuid}
      onSuccess={handleSuccess}
    />
  );

  return (
    <div className={cx("unpublished-event")}>
      <div className={cx("content")}>
        {!selectedCategory && (
          <div className={cx("content__tips")}>
            <Tips type={tipType} title={t("unpublishedEvent")} />
          </div>
        )}

        <div className={cx("content__eventList")}>
          {selectedCategory &&
            unpublishedEventListContent?.[language]?.map(renderEventCard)}

          {!Boolean(unpublishedEventListContent?.[language].length) && !isEventListLoading && selectedCategory && (
            <div className={styles["content__empty-text"]}>Немає неопублікованих новин</div>
          )}
        </div>

        {selectedCategory && unpublishedEventList.totalPages > 1 && (
          <div className={cx("pagination")}>
            <PaginationComponent
              itemsPerPage={1}
              totalPages={unpublishedEventList.totalPages}
              forcePage={selectedPage}
              onChanePage={(page) => {
                setSelectedPage(page - 1);
                dispatch(getUnPublishedEvent({ page: page - 1, category: selectedCategory }));
              }}
            />
          </div>
        )}
      </div>

      {!selectedCategory && (
        <div className={cx("unpublished-event__img")}>
          <img src={dogTip1} alt="choose-icon-2" />
        </div>
      )}

      {isEventListLoading && <Loader />}
    </div>
  );
};

export default UnpublishedEvent;
