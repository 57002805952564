import { Parser } from "simple-text-parser";

export const TextParser = (text: string) => {
  // TODO: maybe remove parser library, just use replace ?
  const parser = new Parser();

  const urlExpression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*[^()"., \s])?/gi;
  parser.addRule(urlExpression, (tag, clean_tag) => {
    const html = `<a target="_blank" href=${tag}>${tag}</a>`;
    return { type: "tag", text: html, value: clean_tag };
  });

  parser.addRule(/\n/g, () => ({ type: "text", text: "<br />", value: "" }));

  return <div dangerouslySetInnerHTML={{ __html: parser.render(text) }} />;
};
