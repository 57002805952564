import React, { useEffect, useState } from "react";
import SaveButton from "../../components/SaveButton";
import ModalWarning, { ErrorText, SuccessText } from "../ModalWarning";
import Tips, { TIPS_TYPE } from "../Tips";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const StepSave = ({
  isOpenModalOuter,
  setIsOpenModalOuter = () => {},
  successHandler,
  cancelHandler = () => {},
  isSuccess,
  errorText,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(isOpenModalOuter);
  const { t } = useTranslation(["modals"]);

  useEffect(() => {
    setIsOpenModal(isOpenModalOuter);
  }, [isOpenModalOuter]);

  return (
    <>
      <div className={styles["step-save"]}>
        {/* Removed because take some space & notifications overlap this save button */}
        {/* <Tips type={TIPS_TYPE.SAVE} /> */}
        <SaveButton
          onClick={() => {
            setIsOpenModal(!isOpenModal);
            setIsOpenModalOuter(!isOpenModal);
          }}
        />
        <ModalWarning
          isOpen={isOpenModal}
          setIsOpenModal={() => {
            setIsOpenModal(!isOpenModal);
            setIsOpenModalOuter(!isOpenModal);
          }}
          cancelHandler={cancelHandler}
          successHandler={successHandler}
          title={
            isSuccess ? (
              <span>
                <Trans i18nKey="modals:areYouShure">
                  Ти впевнений, що <SuccessText>все вірно зробив</SuccessText>?
                </Trans>
              </span>
            ) : errorText ? (
              <span>
                <Trans
                  i18nKey="modals:cannotSaveNoFullData_2"
                  errorText={errorText}
                >
                  {{ errorText }}, тому ти{" "}
                  <ErrorText isUpperCase={false}>не можеш зберегти</ErrorText>!
                </Trans>
              </span>
            ) : (
              <span>
                <Trans i18nKey="modals:cannotSaveNoFullData">
                  Усі поля не заповнені, тому ти{" "}
                  <ErrorText isUpperCase={false}>не можеш зберегти</ErrorText>!
                </Trans>
              </span>
            )
          }
        />
      </div>
    </>
  );
};

export default StepSave;
