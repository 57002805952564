/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CopyToBufferButton from "../../../components/CopyToBufferButton";
import {
  markItemAsDirty,
  markItemAsValid,
  markItemAsVisited,
  saveStepPageToStore,
  selectStepsPages,
  setStepPageData,
} from "../../../store/slices/steps";
import { selectSelectedEventCategoryId } from "store/slices/eventCategory";
import { LANGS } from "../../../constants/lang";
import StepSave from "../../StepSave";
import AutoTranslateButton from "../../../components/AutoTranslateButton";
import AutoResizeTextArea from "../../../components/AutoResizeTextArea";
import { useTranslation } from "react-i18next";

import "./index.scss";

const EventDescStepPage = ({ id }) => {
  const { t } = useTranslation(["common", "event"]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const storedData = useSelector(selectStepsPages);
  const selectedCategory = useSelector(selectSelectedEventCategoryId);
  const { control, handleSubmit, formState, getValues, reset } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      uk: storedData[id - 1].value["uk"],
      en: storedData[id - 1].value["en"],
      de: storedData[id - 1].value["de"],
      fr: storedData[id - 1].value["fr"],
      es: storedData[id - 1].value["es"],
      kk: storedData[id - 1].value["kk"],
      pl: storedData[id - 1].value["pl"],
      ru: storedData[id - 1].value["ru"],
    },
  });
  const { errors, isValid, isDirty } = formState;

  const storeValue = () => {
    dispatch(setStepPageData(id, getValues()));
  };

  useEffect(() => {
    dispatch(markItemAsDirty(id, isDirty));
    dispatch(markItemAsVisited(id));
  }, [isDirty]);

  useEffect(() => {
    if (isValid) {
      dispatch(markItemAsValid(id, true));
    } else {
      dispatch(markItemAsValid(id, false));
    }
  }, [isValid]);

  useEffect(() => {
    const showModal = (e) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        e.preventDefault();
        setIsOpenModal(true);
      }
    };
    window.addEventListener("keydown", showModal);
    return () => window.removeEventListener("keydown", showModal);
  }, []);

  return (
    <div className="event-desc-step">
      <div className="event-desc-step__head">
        <StepSave
          isSuccess={isValid}
          isOpenModalOuter={isOpenModal}
          setIsOpenModalOuter={setIsOpenModal}
          successHandler={() => {
            if (isValid) {
              dispatch(saveStepPageToStore(id, selectedCategory));
            }
          }}
          cancelHandler={() => {}}
        />
      </div>
      <div className="event-desc-step__body">
        <div className="event-desc-step__body-form">
          <form onSubmit={handleSubmit(null)}>
            <span className="event-desc-step__body-title">
              {t("event:enterDescription")}
            </span>
            {Object.keys(LANGS).map((item) => (
              <div className="form-row" key={item}>
                <AutoResizeTextArea
                  alignLeft
                  control={control}
                  name={item}
                  placeholder={LANGS[item]}
                  isPlaceholderWhite
                  onBlur={storeValue}
                  rules={{
                    required: t("common:form.validation.required"),
                  }}
                  errors={errors[item]}
                  centered
                />
                <CopyToBufferButton
                  getText={() => getValues(item)}
                  onCopy={(txt) => {
                    reset({
                      uk: txt,
                      en: txt,
                      de: txt,
                      fr: txt,
                      es: txt,
                      kk: txt,
                      pl: txt,
                      ru: txt,
                    });
                    storeValue();
                  }}
                />
                <AutoTranslateButton
                  getText={() => ({
                    currentText: getValues(item),
                    allTextValues: getValues(),
                    langs: LANGS,
                  })}
                  onTranslate={(data) => {
                    reset(data);
                    storeValue();
                  }}
                />
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventDescStepPage;
