import React, { useState, useEffect } from "react";

import ReactDOM from "react-dom";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";

import { useForm } from "react-hook-form";
import { LANGS } from "constants/lang";
import Input from "components/Input";
import CopyToBufferButton from "components/CopyToBufferButton";
import AutoTranslateButton from "components/AutoTranslateButton";
import { useTranslation } from "react-i18next";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";

import styles from "./index.module.scss";

const ModalFormEvent = ({
  title,
  isOpen,
  setIsOpenModal,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const { t } = useTranslation(["button", "common"]);
  const [mounted, setMounted] = useState(false);
  const { control, handleSubmit, formState, getValues, reset } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
    },
  });
  const { errors, isValid } = formState;

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  const onSubmit = (data) => {
    successHandler(data);
    setIsOpenModal();
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        uk: "",
        en: "",
        de: "",
        fr: "",
        es: "",
        kk: "",
        pl: "",
      });
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div
        ref={innerRef}
        className={styles["modal-cancel"]}
        onClick={fixNestedModalsCloseTrigger}
      >
        <form onSubmit={null}>
          <div className={styles["modal-cancel__text"]}>{title}</div>
          {Object.keys(LANGS).map((item) => (
            <div className={styles["modal-cancel__row"]} key={item}>
              <Input
                control={control}
                name={item}
                placeholder={LANGS[item]}
                isPlaceholderWhite
                rules={{
                  required: t("common:form.validation.required"),
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (isValid) {
                      handleSubmit(onSubmit)();
                    }
                  }
                }}
                centered
                errors={errors[item]}
                size="sm"
              />
              <CopyToBufferButton
                getText={() => getValues(item)}
                onCopy={(txt) => {
                  reset({
                    uk: txt,
                    en: txt,
                    de: txt,
                    fr: txt,
                    es: txt,
                    kk: txt,
                    pl: txt,
                    ru: txt,
                  });
                }}
              />
              <AutoTranslateButton
                getText={() => ({
                  currentText: getValues(item),
                  allTextValues: getValues(),
                  langs: LANGS,
                })}
                onTranslate={(data) => {
                  reset(data);
                }}
              />
            </div>
          ))}
          <div className={styles["modal-cancel__btns"]}>
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid}>
              {t("button:save")}
            </Button>
            <Button
              onClick={() => {
                cancelHandler();
                setIsOpenModal();
              }}
              type="cancel"
            >
              {t("button:cancel")}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ModalFormEvent;
